<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import { OverlayTypes } from '@/store/types/overlay'

export default {
  data() {
    return {
      overlayName: '#order',
    }
  },
  methods: {
    ...mapActions('overlay', {
      passProps: OverlayTypes.actions.PASS_PROPS,
      setOverlay: OverlayTypes.actions.OPEN_OVERLAY,
    }),
    openDialog(checkedFundings) {
      this.passProps({ checkedFundings })
      this.setOverlay(this.overlayName)
    },
  },
}
</script>

<style lang="scss" scoped></style>
